export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    
    fetchActivities(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/activity/list-acitvity-user', {params:params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/activity/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActivity(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/activity/create', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editActivity(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/activity/edit/${params.id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteActivity(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete('/api/activity/delete', { data: { id: params.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // My activity - Document tab
    fetchDocument(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/attachment/list-document', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // My activity - Attendance
    fetchAttendance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/timeline/list-parcitipant', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkInOTP(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/timeline/check-in-screen', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })      
    },
    // Evalution
    fetchEva(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/evaluation/list-participant', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    eval_template_detail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/submit-form-evaluation/detail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    eval_template_detail_admin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/submit-form-evaluation/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitEvatemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/submit-form-evaluation/create', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editEvatemplateAdmin( ctx, params ) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/submit-form-evaluation/edit/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // editTopic( ctx, params ){
    //   return new Promise((resolve, reject) => {
    //     global.axios
    //       .put(`/api/sub-topic/edit/${params.id}`, params.data )
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
