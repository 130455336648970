export default [
  {
    path: '/admin/dashboard',
    name: 'dashboard-admin',
    component: () => import('@/views/itees/admin-dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      auth: true,
      group: 1,
    },
  },
  {
    path: '/admin/our-portfolio',
    meta: { label: 'Our Portfolio' },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'our-portfolio',
        component: () =>
          import('@/views/itees/admin-dashboard/portfolio/Portfolio.vue'),
        meta: {
          auth: true,
          group: 1,
          title: 'Our Portfolio',
        },
      },
      {
        path: '/admin/our-portfolio/project',
        name: 'our-portfolio-project',
        redirect: '/admin/our-portfolio/project',
        meta: {
          label: 'Project Listing',
          auth: true,
          group: 1,
          navActiveLink: 'our-portfolio',
        },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            component: () =>
              import('@/views/itees/admin-dashboard/portfolio/Project.vue'),
            meta: {
              title: 'Project Listing',
              auth: true,
              group: 1,
            },
          },
          {
            path: 'add',
            name: 'our-portfolio-project-add',
            component: () =>
              import('@/views/itees/admin-dashboard/portfolio/Single.vue'),
            meta: {
              title: 'Add project',
              label: 'Add project',
              auth: true,
              group: 1,
              navActiveLink: 'our-portfolio',
            },
          },
          {
            path: ':id',
            name: 'our-portfolio-project-detail',
            meta: { label: '--', navActiveLink: 'our-portfolio' },
            component: {
              render(c) {
                return c('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'our-portfolio-project-detail-blank',
                component: () =>
                  import(
                    '@/views/itees/admin-dashboard/portfolio/Overview-Detail.vue'
                  ),
                meta: {
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                },
              },
              {
                path: 'add',
                name: 'our-portfolio-project-detail-add',
                component: () =>
                  import('@/views/itees/admin-dashboard/portfolio/Single.vue'),
                meta: {
                  title: 'Add project',
                  label: 'Add project',
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                },
              },
              {
                path: 'edit/:project_id',
                name: 'our-portfolio-project-detail-edit',
                component: () =>
                  import('@/views/itees/admin-dashboard/portfolio/Single.vue'),
                meta: {
                  title: '--',
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                },
                component: {
                  render(c) {
                    return c('router-view')
                  },
                },
                children: [
                  {
                    path: '',
                    name: 'our-portfolio-project-detail-edit-blank',
                    component: () =>
                      import(
                        '@/views/itees/admin-dashboard/portfolio/Single.vue'
                      ),
                    meta: {
                      label: '--',
                      title: '--',
                      auth: true,
                      group: 1,
                      navActiveLink: 'our-portfolio',
                    },
                  },
                  {
                    path: 'add-activity',
                    name: 'our-portfolio-project-detail-edit-addactivity',
                    component: () =>
                      import(
                        '@/views/itees/admin-dashboard/portfolio/projectListing/activity/Add.vue'
                      ),
                    meta: {
                      title: 'Add Activity',
                      label: 'Add Activity',
                      auth: true,
                      group: 1,
                      navActiveLink: 'our-portfolio',
                      subLabel_click: '--',
                      tabIndex: 1,
                    },
                  },
                  {
                    path: 'activity/:activity_id',
                    name: 'our-portfolio-project-detail-edit-editactivity',
                    component: () =>
                      import(
                        '@/views/itees/admin-dashboard/portfolio/projectListing/activity/Edit.vue'
                      ),
                    meta: {
                      title: '--',
                      label: '--',
                      auth: true,
                      group: 1,
                      navActiveLink: 'our-portfolio',
                      subLabel_2_click: '--',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'evaluation/:evalution_id',
            name: 'our-portfolio-project-detail-edit-editactivity-evaluation',
            component: () =>
              import(
                '@/views/itees/admin-dashboard/portfolio/projectListing/activity/evaluation/EvalutionSingle.vue'
              ),
            meta: {
              title: 'Evaluation title',
              label: '--',
              auth: true,
              group: 1,
            },
          },
        ],
      },
      {
        path: '/admin/our-portfolio/study-visit',
        redirect: '/admin/our-portfolio/study-visit',
        meta: {
          title: 'Study Visit Listing',
          label: 'Study Visit Listing',
          auth: true,
          group: 1,
        },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'our-portfolio-study-visit',
            component: () =>
              import(
                '@/views/itees/admin-dashboard/portfolio/studyVisit/List.vue'
              ),
            meta: {
              auth: true,
              group: 1,
              navActiveLink: 'our-portfolio',
              title: 'Study Visit Listing',
            },
          },
          {
            path: 'add-visit',
            name: 'our-portfolio-study-visit-add',
            component: () =>
              import(
                '@/views/itees/admin-dashboard/portfolio/studyVisit/tabs/Single.vue'
              ),
            meta: {
              auth: true,
              group: 1,
              navActiveLink: 'our-portfolio',
              title: 'Study Visit Listing',
              label: 'Add Visit',
            },
          },
          {
            path: ':id',
            name: 'our-portfolio-study-detail',
            meta: { label: ' ', navActiveLink: 'our-portfolio' },
            component: {
              render(c) {
                return c('router-view')
              },
            },
            children: [
              {
                path: '',
                name: 'our-portfolio-study-detail-blank',
                component: () =>
                  import(
                    '@/views/itees/admin-dashboard/portfolio/studyVisit/tabs/Single.vue'
                  ),
                meta: {
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                },
              },
              {
                path: 'add-visit',
                name: 'our-portfolio-study-visit-add',
                component: () =>
                  import(
                    '@/views/itees/admin-dashboard/portfolio/studyVisit/tabs/Single.vue'
                  ),
                meta: {
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                  title: 'Add Vist Listing',
                  label: 'Add Visit',
                },
              },
              {
                path: 'edit/:project_id',
                name: 'our-portfolio-study-detail-edit',
                component: () =>
                  import(
                    '@/views/itees/admin-dashboard/portfolio/studyVisit/tabs/Single.vue'
                  ),
                meta: {
                  title: 'Study Visit',
                  label: '--',
                  auth: true,
                  group: 1,
                  navActiveLink: 'our-portfolio',
                },
              },
            ],
          },
        ],
      },
      {
        path: '/admin/our-portfolio/activity-listing',
        redirect: '/admin/our-portfolio/activity-listing',
        meta: {
          title: 'Activity Listing',
          auth: true,
          group: 1,
        },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'our-portfolio-activity-listing',
            component: () =>
              import(
                '@/views/itees/admin-dashboard/portfolio/activityList/List.vue'
              ),
            meta: {
              auth: true,
              group: 1,
              navActiveLink: 'our-portfolio',
              label: 'Activity Listing',
              title: 'Activity Listing',
            },
          },
          {
            path: ':id',
            name: 'our-portfolio-activity-listing-detail',
            component: () =>
              import(
                '@/views/itees/admin-dashboard/portfolio/activityList/List.vue'
              ),
            meta: {
              auth: true,
              group: 1,
              navActiveLink: 'our-portfolio',
              label: 'Activity Listing',
              title: 'Activity Listing',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin/itees-product',
    name: 'itees-product',
    redirect: '/admin/itees-product',
    meta: {
      label: 'ITEES Products',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import('@/views/itees/admin-dashboard/products/List.vue'),
        meta: {
          auth: true,
          group: 1,
          title: 'ITEES Product',
        },
      },
      {
        path: 'add-product',
        name: 'itees-product-add',
        component: () =>
          import('@/views/itees/admin-dashboard/products/Add.vue'),
        meta: {
          auth: true,
          group: 1,
          label: 'Create Product',
          title: 'Create Product',
          navActiveLink: 'itees-product',
        },
      },
      {
        path: ':id',
        name: 'itees-product-detail',
        meta: { navActiveLink: 'itees-product', title: 'ITEES Product' },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'itees-product-detail-blank',
            component: () =>
              import('@/views/itees/admin-dashboard/products/Edit.vue'),
            meta: {
              label: '--',
              title: 'ITEES Product',
              auth: true,
              group: 1,
              navActiveLink: 'itees-product',
            },
          },
          {
            path: 'add-sub-topic',
            name: 'itees-product-addtopic',
            component: () =>
              import('@/views/itees/admin-dashboard/products/Edit.vue'),
            meta: {
              label: 'New Sub Topic',
              title: 'New Sub Topic',
              auth: true,
              group: 1,
              navActiveLink: 'itees-product',
              namedetail: '--',
              tabIndex: 1,
              nameDetailActive: 'itees-product-detail',
            },
          },
          {
            path: 'edit/:topic_id',
            name: 'itees-product-edittopic',
            component: () =>
              import('@/views/itees/admin-dashboard/products/Edit.vue'),
            meta: {
              label: '--',
              title: 'Edit Sub Topic',
              auth: true,
              group: 1,
              navActiveLink: 'itees-product',
              namedetail: '--',
              tabIndex: 1,
              nameDetailActive: 'itees-product-detail',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin/itees-strategic-plan',
    redirect: '/admin/itees-strategic-plan',
    meta: {
      label: 'ITEES  Strategic Plan',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'itees-strategic',
        component: () =>
          import('@/views/itees/admin-dashboard/strategic/List.vue'),
        meta: {
          auth: true,
          group: 1,
          title: 'ITEES  Strategic Plan',
        },
      },
      {
        path: 'add-fy',
        meta: {
          title: 'Add Financial Year',
          label: 'Add Financial Year',
          navActiveLink: 'itees-strategic',
        },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'itees-strategic-addfy',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/AddFY.vue'),
            meta: {
              auth: true,
              group: 1,
              title: 'Add Financial Year',
            },
          },
        ],
      },
      {
        path: ':id',
        name: 'itees-strategic-detail',
        component: () =>
          import('@/views/itees/admin-dashboard/strategic/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'itees-strategic',
        },
      },
      {
        path: 'edit/:id',
        meta: {
          title: '--',
          auth: true,
          group: 1,
          navActiveLink: 'itees-strategic',
        },
        component: {
          render(c) {
            return c('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'itees-strategic-edit',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: '--',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
            },
          },
          {
            path: 'add-goal',
            name: 'itees-strategic-detail-addgoal',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Add New Goal',
              title: 'Add New Goal',
              auth: true,
              group: 1,
              namedetail: '--',
              navActiveLink: 'itees-strategic',
              tabIndex: 1,
              child_tab_01: 0,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'goal/edit/:goal_id',
            name: 'itees-strategic-edit-goal',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Goal',
              title: 'Goal',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 0,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'goal/:goal_id',
            name: 'itees-strategic-detail-goal',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Goal',
              title: 'Goal',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 0,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'add-strategy',
            name: 'itees-strategic-detail-addstrategy',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Add New Strategy',
              title: 'Add New Strategy',
              auth: true,
              group: 1,
              namedetail: '--',
              navActiveLink: 'itees-strategic',
              tabIndex: 1,
              child_tab_01: 1,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'strategy/edit/:stra_id',
            name: 'itees-strategic-detail-editstra',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Strategy',
              title: 'Strategy',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 1,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'strategy/:stra_id',
            name: 'itees-strategic-detail-stra',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Strategy',
              title: 'Strategy',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 1,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'add-programme',
            name: 'itees-strategic-detail-addprogramme',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Add Programme',
              title: 'Add Programme',
              auth: true,
              group: 1,
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 2,
              navActiveLink: 'itees-strategic',
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'programme/edit/:pro_id',
            name: 'itees-strategic-detail-editprogramme',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Programme',
              title: 'Programme',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 2,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
          {
            path: 'programme/:pro_id',
            name: 'itees-strategic-detail-programme',
            component: () =>
              import('@/views/itees/admin-dashboard/strategic/Single.vue'),
            meta: {
              label: 'Programme',
              title: 'Programme',
              auth: true,
              group: 1,
              navActiveLink: 'itees-strategic',
              namedetail: '--',
              tabIndex: 1,
              child_tab_01: 2,
              nameDetailActive: 'itees-strategic-edit',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin/organisations',
    name: 'itees-organisations',
    redirect: '/admin/organisations',
    meta: {
      label: 'Organisations',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import('@/views/itees/admin-dashboard/organizations/List.vue'),
        meta: {
          auth: true,
          group: 1,
          title: 'Organisations',
        },
      },
      {
        path: 'add',
        name: 'itees-organisations-add',
        component: () =>
          import('@/views/itees/admin-dashboard/organizations/Add.vue'),
        meta: {
          title: 'Add Organisation',
          label: 'Add Organisation',
          auth: true,
          group: 1,
          navActiveLink: 'itees-organisations',
        },
      },
      {
        path: ':id',
        name: 'itees-organisations-detail',
        component: () =>
          import('@/views/itees/admin-dashboard/organizations/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'itees-organisations',
        },
      },
      {
        path: 'edit/:id',
        name: 'itees-organisations-edit',
        component: () =>
          import('@/views/itees/admin-dashboard/organizations/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'itees-organisations',
        },
      },
    ],
  },
  {
    path: '/admin/participant-management',
    name: 'participant-management',
    redirect: '/admin/participant-management',
    meta: {
      label: 'Participant Management',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import('@/views/itees/admin-dashboard/participant/List.vue'),
        meta: {
          title: 'Participant Management',
          auth: true,
          group: 1,
        },
      },
      {
        path: 'add-participant',
        name: 'participant-add',
        component: () =>
          import('@/views/itees/admin-dashboard/participant/Add.vue'),
        meta: {
          title: 'Add Participant',
          label: 'Add Participant',
          auth: true,
          group: 1,
          navActiveLink: 'participant-management',
        },
      },
      {
        path: ':id',
        name: 'participant-detail',
        component: () =>
          import('@/views/itees/admin-dashboard/participant/Edit.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'participant-management',
        },
      },
      {
        path: 'edit/:id',
        name: 'participant-edit',
        component: () =>
          import('@/views/itees/admin-dashboard/participant/Edit.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'participant-management',
        },
      },
    ],
  },
  {
    path: '/admin/staff-management',
    name: 'staff-management',
    redirect: '/admin/staff-management',
    meta: {
      label: 'Staff Management',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () => import('@/views/itees/admin-dashboard/staff/List.vue'),
        meta: {
          title: 'Staff Management',
          auth: true,
          group: 1,
        },
      },
      {
        path: 'add-staff',
        name: 'staff-management-add',
        component: () => import('@/views/itees/admin-dashboard/staff/Add.vue'),
        meta: {
          title: 'Add New Staff',
          label: 'Add New Staff',
          auth: true,
          group: 1,
          navActiveLink: 'staff-management',
        },
      },
      {
        path: ':id',
        name: 'staff-management-detail',
        component: () =>
          import('@/views/itees/admin-dashboard/staff/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'staff-management',
        },
      },
      {
        path: 'edit/:id',
        name: 'staff-management-edit',
        component: () =>
          import('@/views/itees/admin-dashboard/staff/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'staff-management',
        },
      },
      {
        path: 'TVET-experts/:id',
        name: 'staff-management-expert-detail',
        component: () =>
          import('@/views/itees/admin-dashboard/staff/expert/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          navActiveLink: 'staff-management',
          types: '--',
          parentTab: 2,
          tabIndex: 1,
        },
      },
      {
        path: 'TVET-experts/edit/:id',
        name: 'staff-management-expert-edit',
        component: () =>
          import('@/views/itees/admin-dashboard/staff/expert/Single.vue'),
        meta: {
          title: '--',
          label: '--',
          titleOnlyView: 'TVET Expert',
          auth: true,
          group: 1,
          navActiveLink: 'staff-management',
          types: '--',
          parentTab: 2,
          tabIndex: 1,
        },
      },
    ],
  },
  {
    path: '/admin/TVET-experts-applications',
    name: 'experts-applications',
    meta: {
      label: 'TVET Experts Applications',
    },
    redirect: '/admin/TVET-experts-applications',
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        component: () =>
          import('@/views/itees/admin-dashboard/experts-applications/List.vue'),
        meta: {
          title: 'TVET Experts Applications',
          auth: true,
          group: 1,
        },
      },
      {
        path: 'edit/:id',
        name: 'experts-edit',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/experts-applications/Single.vue'
          ),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          types: '--',
          tabIndex: 1,
          navActiveLink: 'experts-applications',
        },
      },
      {
        path: ':id',
        name: 'experts-view-detail',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/experts-applications/Single.vue'
          ),
        meta: {
          title: '--',
          label: '--',
          auth: true,
          group: 1,
          types: '--',
          tabIndex: 1,
          navActiveLink: 'experts-applications',
        },
      },
    ],
  },
  {
    path: '/admin/administrations',
    name: 'staff_administrations',
    redirect: '/admin/administrations',
    meta: {
      label: 'Administrations',
    },
    component: {
      render(c) {
        return c('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'staff_administrations_blank',
        component: () =>
          import('@/views/itees/admin-dashboard/administrations/List.vue'),
        meta: {
          title: 'Administrations',
          auth: true,
          group: 1,
        },
      },
      {
        path: 'userful-info/:id',
        name: 'administrations_userfulInfo_detail',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/userfulInfo/Detail.vue'
          ),
        meta: {
          title: '',
          label: 'Useful Info(P)',
          auth: true,
          group: 1,
          sublabel: 'Content Info',
          tabIndex: 1,
          sublabel_01: 'Useful Info(P)',
          tabIndex_01: 1,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'userful-info/edit/:id',
        name: 'administrations_userfulInfo_edit',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/userfulInfo/Edit.vue'
          ),
        meta: {
          title: '',
          label: 'Useful Info(P)',
          auth: true,
          group: 1,
          sublabel: 'Content Info',
          tabIndex: 1,
          sublabel_01: 'Useful Info(P)',
          tabIndex_01: 1,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'add-userful-info',
        name: 'administrations_userful_add',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/userfulInfo/Add.vue'
          ),
        meta: {
          title: 'Add Useful Info(P)',
          label: 'Add New',
          auth: true,
          group: 1,
          sublabel: 'Content Info',
          tabIndex: 1,
          sublabel_01: 'Useful Info(P)',
          tabIndex_01: 1,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'add-service',
        name: 'administrations_service_add',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/services/Add.vue'
          ),
        meta: {
          title: 'Add Service',
          label: 'Add Service',
          auth: true,
          group: 1,
          sublabel: 'Services Setup',
          tabIndex: 2,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'service/edit/:id',
        name: 'administrations_service_edit',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/services/Edit.vue'
          ),
        meta: {
          title: 'Service',
          label: 'Service',
          auth: true,
          group: 1,
          sublabel: 'Services Setup',
          tabIndex: 2,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'service/:id',
        name: 'administrations_service_detail',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/services/Detail.vue'
          ),
        meta: {
          title: 'Service',
          label: 'Service',
          auth: true,
          group: 1,
          sublabel: 'Services Setup',
          tabIndex: 2,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'add-country',
        name: 'administrations_country_add',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/country/Add.vue'
          ),
        meta: {
          title: 'Add Country',
          label: 'Add Country',
          auth: true,
          group: 1,
          sublabel: 'Country Setup',
          tabIndex: 3,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'country/:id',
        name: 'administrations_country_detail',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/country/Detail.vue'
          ),
        meta: {
          title: 'Country',
          label: 'Country',
          auth: true,
          group: 1,
          sublabel: 'Country Setup',
          tabIndex: 3,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'country/edit/:id',
        name: 'administrations_country_edit',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/country/Edit.vue'
          ),
        meta: {
          title: 'Country',
          label: 'Country',
          auth: true,
          group: 1,
          sublabel: 'Country Setup',
          tabIndex: 3,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'add-project-code',
        name: 'administrations_project_add',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/projectcode/Add.vue'
          ),
        meta: {
          title: 'Add Project Code',
          label: 'Add Project Code',
          auth: true,
          group: 1,
          sublabel: 'Project Code',
          tabIndex: 4,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'project-code/:id',
        name: 'administrations_projectcode_detail',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/projectcode/Detail.vue'
          ),
        meta: {
          title: 'Project Code',
          label: 'Project Code',
          auth: true,
          group: 1,
          sublabel: 'Project Code',
          tabIndex: 4,
          navActiveLink: 'staff_administrations',
        },
      },
      {
        path: 'project-code/edit/:id',
        name: 'administrations_projectcode_edit',
        component: () =>
          import(
            '@/views/itees/admin-dashboard/administrations/projectcode/Edit.vue'
          ),
        meta: {
          title: 'Project Code',
          label: 'Project Code',
          auth: true,
          group: 1,
          sublabel: 'Project Code',
          tabIndex: 4,
          navActiveLink: 'staff_administrations',
        },
      },
    ],
  },
  {
    path: '/admin/my-profile',
    name: 'admin_myprofile',
    component: () =>
      import('@/views/itees/admin-dashboard/profile/Profile.vue'),
    meta: {
      label: 'My Profile',
      title: 'My Profile',
    },
  },
]
