export default {
  namespaced: true,
  state: {
    userLogin: null,
    permissionOurPortfolio: []
  },
  getters: {
    user: (state) => state.userLogin,
  },
  mutations: {
    SET_USER_LOGIN(state, data) {
      state.userLogin = data
    },
    SET_PERMISSIONS_OUR_PORTFOLIO(state, data) {
      state.permissionOurPortfolio= data
    }
  },
  actions: {
    fetchUserful(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/usefu-information/list', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteUserful(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/usefu-information/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchUserfulDetail(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/usefu-information/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    login(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/login', params)
          .then((response) => {
            if(response.data.data.first_login == 0){
              localStorage.setItem(
                'access_token',
                response.data.data.access_token,
              )
              localStorage.setItem(
                'refresh_token',
                response.data.data.refresh_token,
              )
              localStorage.setItem('userData', JSON.stringify(response.data.data))
              global.axios.defaults.headers.common['Authorization'] =
                'Bearer ' + response.data.data.access_token
						}else{
              localStorage.setItem('userDataFirstLogin', JSON.stringify(response.data.data))
            }
            
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    changePasswordFirst(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/change-password-first', params)
          .then((response) => {
            localStorage.removeItem('userDataFirstLogin')
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    logout(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete('/api/logout')
          .then((response) => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('userData')
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    userInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/info')
          .then((response) => {
            ctx.commit('SET_USER_LOGIN', response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
          
      })
    },
    userProfile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/my-profile/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    userProfileExpert(ctx) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-web/my-profile')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    saveProfile(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/my-profile/change`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    saveProfileStaff(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/user/change-profile`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    saveProfileExpert(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put('/api/expert-web/edit-my-profile', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    changPass(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/my-profile/change-password`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    refreshToken(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/refresh-myprofile', {
            refresh_token: localStorage.getItem('refresh_token')
          })
          .then((response) => {
            localStorage.setItem('userData', JSON.stringify(response.data.data))
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    refreshToken2(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/refresh', {
            refresh_token: localStorage.getItem('refresh_token')
          })
          .then((response) => {
            localStorage.setItem(
              'access_token',
              response.data.data.access_token,
            )
            localStorage.setItem(
              'refresh_token',
              response.data.data.refresh_token,
            )
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    fetchAdministrator(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/activity_log/list', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAdministratorAccessLog(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/access-log/list', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetch_staff_log_list(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/user/list', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetch_module_log_list(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/list-module', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailStaff(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/user/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editStaff(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/user/edit/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteStaff(ctx, { id, is_delete }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/user/delete/${id}?is_delete=${is_delete}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    createStaff(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/user/create`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // role
    role_list(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/role/list', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    //
    fetchServicesSetup(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/service_setup/list', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailServicesSetup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`api/service_setup/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUseful(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file_upload', params.image)
        formData.append('status', params.status)
        formData.append('title', params.title)
        formData.append('description', params.description)
        formData.append('type', params.type)

        global.axios
          .post('/api/usefu-information/create', formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addUseful_Schedule(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file_upload', params.image)
        formData.append('status', params.status)
        formData.append('title', params.title)
        formData.append('description', params.description)
        formData.append('type', params.type)

        formData.append(
          'published_date',
          params.published_date ? params.published_date : null,
        )
        formData.append(
          'published_time',
          params.published_time ? params.published_time : null,
        )

        global.axios
          .post('/api/usefu-information/create', formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editUseful_info(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file_upload', params.data.image)
        formData.append('status', params.data.status)
        formData.append('title', params.data.title)
        formData.append('description', params.data.description)
        formData.append('type', params.data.type)

        global.axios
          .post(`/api/usefu-information/edit/${params.id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editUseful_info_Schedule(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file_upload', params.data.image)
        formData.append('status', params.data.status)
        formData.append('title', params.data.title)
        formData.append('description', params.data.description)
        formData.append('type', params.data.type)

        formData.append(
          'published_date',
          params.data.published_date ? params.data.published_date : null,
        )
        formData.append(
          'published_time',
          params.data.published_time ? params.data.published_time : null,
        )

        global.axios
          .post(`/api/usefu-information/edit/${params.id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    changeStatusSchedule(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append(
          'published_date',
          params.published_date ? params.published_date : null,
        )
        formData.append(
          'published_time',
          params.published_time ? params.published_time : null,
        )

        global.axios
          .post(
            `/api/usefu-information/change-publish/${params.id}/${params.status}`,
            formData,
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post(
            `/api/usefu-information/change-publish/${params.id}/${params.status}`,
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    downloadFilebyUrl(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/downLoad', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // document
    fetchDocumentInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/attachment/list-document', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteDocument(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/attachment/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addDocument(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('attachable_id', params.attachable_id)
        formData.append('attachable_type', params.attachable_type)

        formData.append('file_upload', params.file)
        formData.append('name', params.name)
        formData.append('remarks', params.remarks)

        global.axios
          .post('/api/attachment/create-document', formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editDocument(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file_upload', params.data.file)
        formData.append('name', params.data.name)
        formData.append('remarks', params.data.remarks)

        global.axios
          .post(`/api/attachment/edit-document/${params.id}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // Services setup
    addServiceSetup(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()

        formData.append('name', params.name)
        formData.append('acronym', params.acronym)
        formData.append('status', params.status)

        global.axios
          .post('/api/service_setup/create', formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteServiceSetup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/service_setup/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchServiceSetup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/service_setup/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editServiceSetup(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/service_setup/edit/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // Country
    fetchCountry(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/country/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/country/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAllCountry(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/list/list-all-country`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addCountry(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/country/create', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/country/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editCountry(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/country/edit/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    //Project code
    fetchProjectCodeList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/project_code/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addProjectCode(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/project_code/create', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteProjectCode(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/project_code/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailProjectCode(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/project_code/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editProjectCode(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/project_code/edit/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // Client
    Client_Organisation_List(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/organisation/list`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailOrganisation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/organisation/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    editOr(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/organisation/edit/${params.id}`, params.data)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    deleteOrganisation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/organisation/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    addOr(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/organisation/create`, params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    // chart
    chartHead_Or(ctx, { type }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/organisation/alumni-statistic-header/0/${type}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    chartHead_detail_Or(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(
            `/api/organisation/alumni-statistic-header/${params.id}/${params.type}`,
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    chart_Or(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(
            `/api/organisation/alumni-statistic-chart/0/${queryParams.type}`,
            { params: queryParams },
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    chartDetail_Or(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(
            `/api/organisation/alumni-statistic-chart/${params.id}/${params.type}`,
            { params },
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetch_staff_list(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/user/list-staff', { params: params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    chartBreakDown_Or( ctx, params ) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/organisation/alumni-statistic-footer-country-breakdown`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    chartActivityContribution_Or( ctx, params ) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/organisation/alumni-statistic-footer-activity-contribution/${params.id}/${params.type}`, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    //module
    fetch_module_permission(ctx) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/list-module-role-permission')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetch_list_module_permission(ctx) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/list-module-permission')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updatedPermission(ctx, params){
      return new Promise((resolve, reject) => {
        global.axios
          .post('/api/role-permision-update-2', params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    checkPermisionOurPortfolio(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/check-permission-our-portfolio', { params: queryParams })
          .then(response => {
            ctx.commit("SET_PERMISSIONS_OUR_PORTFOLIO", response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
