<template>
    <div>
        <b-form-input
            id="input-formatter"
            v-model="displayValue"
            placeholder="8,888.00"
            @blur="isInputActive = false" 
            @focus="isInputActive = true"
            :disabled="disabled"
        ></b-form-input>
    </div>
</template>

<script>
    export default {
        name: "money-input",
        props: {
            value: {
                default: () => 0
            },
            disabled: {
                default: () => false
            }
        },

        data() {
            return {
                isInputActive:false,
            }
        },
        watch: {},
        computed: {
            displayValue: {
                get: function() {
                    // console.log('this.value: ', this.value)
                    if (this.isInputActive) {
                        return this.value.toString()
                    } else {
                        return Number(this.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                    }
                },
                set: function(modifiedValue) {
                    let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                    if (isNaN(newValue)) {
                        newValue = 0
                    }
                    this.$emit('input', newValue)
                }
            },
        },
    }
</script>

<style scoped>

</style>
