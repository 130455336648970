import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

Vue.use(BootstrapVue);
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'
import './filters'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import Axios from '@/libs/axios'
// 
import iteesFormInputCurrency from "@/views/itees/custom-component/InputCurrency"
Vue.component("itees-form-input-currency", iteesFormInputCurrency)

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

window.$ = window.jQuery = require('jquery');
console.log(process.env.VUE_APP_SERVER_URL, "process.env.VUE_APP_SERVER_URL")
global.axios = Axios
global.axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL || 'https://api-itees-development.vinova.sg'
let accessToken = localStorage.getItem('access_token')
if (accessToken) global.axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

// start refresh token
	
// end refresh token

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
