import Vue from 'vue'
const moment = require('moment');

Vue.filter('formatDatebyMoment', function(value, currentformat, newformat, defaultValue = null) {
    if (!value) return defaultValue;
    if (currentformat) {
        return moment(value, currentformat).format(newformat);
    } else {
        return moment(value).format(newformat);
    }
});