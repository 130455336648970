import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import iteesRouter from './routes/itees-router'
import iteesParticipant from './routes/itees-participant'
import iteesExperts from './routes/itees-experts'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

  routes: [    
    //{ path: '/', redirect: { name: 'dashboard-admin' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...iteesRouter,
    ...iteesParticipant,
    ...iteesExperts,
    // {
    //   path: '*',
    //   redirect: 'error-404',
    // },

    {
      path: '*',
      component: {
        render(c) { return c('router-view') },
      },
      children: [{
        path: '',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
          layout: 'full',
          pageTitle: 'Not Found',
        },
      }],
    },
  ],
})

router.beforeEach((to, _, next) => {

  const isLoggedIn = isUserLoggedIn()

  const {meta, name} = to;

  if(meta.title)  document.title = meta.title + ' - ITEES | Your Global Partner of Choice'; //set title on tab google - first
  else if(to.name == 'auth-login') document.title = 'LOGIN - ITEES | Your Global Partner of Choice';
  else document.title = 'ITEES | Your Global Partner of Choice';
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  if (isLoggedIn) {
    const userData = getUserData()
    if (to.meta.redirectIfLoggedIn) next(getHomeRouteForLoggedInUser(userData ? userData.group_id : null))
    
    if (to.path === '/') {
      if (userData.group_id === 1) next( { name: 'dashboard-admin'} )
      if (userData.group_id === 2) next( { name: 'dashboard-participant'} )
      if (userData.group_id === 3) next( { name: 'dashboard-expert'} )
    }

    if(to.meta.group && userData) {
      if((to.meta.group == 1 && userData.group_id != 1) || (to.meta.group == 2 && userData.group_id != 2)) {
        next({ name: 'misc-not-authorized' })
        return
      }
      if((to.meta.group == 1 && to.name == 'staff_administrations_blank' && userData.role.role_id == 4 )) {
        next({ name: 'misc-not-authorized' })
        return
      }
    }
  }

  // renote after done build
  if (to.path === '/' && !isLoggedIn) return next({ name: 'auth-login' })
  if (to.matched.some(record => record.meta.auth) && !isLoggedIn) return next({ name: 'auth-login' })

  return next()

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router


// document.addEventListener('contextmenu', function(e) {
//   e.preventDefault();
// });