export default [
    {
        path: '/participant/dashboard',
        name: 'dashboard-participant',
        component: () => import('@/views/itees/participant-portal/Participant.vue'),
        meta: {
            title: 'Dashboard',
            auth: true,
            group: 2
        }
    },
    {
        path: '/participant/my-activities',
        name: 'my-activities',
        redirect: '/participant/my-activities',
        meta: { label: 'My Activities' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                component: () => import('@/views/itees/participant-portal/activities/List.vue'),
                meta: {
                    title: 'My Activities',
                    auth: true,
                    group: 2
                },
            },
            {
                path: ':id',
                name: "activities-detail",
                meta: {
                    label: '--',
                },
                component: {
                    render(c) { return c('router-view') }
                },
                children:[
                    {
                        path: '',
                        component: () => import('@/views/itees/participant-portal/activities/Single.vue'),
                        meta: {
                            title: 'My Activities',
                            auth: true,
                            group: 2
                        },
                    },
                    {
                        path: 'evaluation/:evalution_id',
                        name: 'my-activities-eva-template',
                        component: () => import('@/views/itees/participant-portal/activities/Single.vue'),
                        meta: {
                            title: 'Evaluation title',
                            label: '--',
                            auth: true,
                            group: 2,
                        },
                    }
                ]
            },
        ]
    },
    {
        path: '/participant/useful-information',
        name: 'useful-information',
        redirect: '/participant/useful-information',
        meta: { label: 'Useful Information' },
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: '',
                component: () => import('@/views/itees/participant-portal/useful/List.vue'),
                meta: {
                    title: 'Useful Information',
                    auth: true,
                    group: 2
                },
            },
            {
                path: ':id',
                component: () => import('@/views/itees/participant-portal/useful/Single.vue'),
                meta: {
                    title: 'Useful Information',
                    label: 'Useful Information - Detail',
                    auth: true,
                    group: 2
                },
            },
        ]
    },
    {
        path: '/participant/my-profile/',
        name: 'my-profile',
        component: () => import('@/views/itees/participant-portal/profile/Profile.vue'),
        meta: {
            title: 'My Profile',
            auth: true,
            group: 2
        }
    },
    
]
