export default [
    {
        path: '/expert/dashboard',
        name: 'dashboard-expert',
        component: () => import('@/views/itees/expert-dashboard/Dashboard.vue'),
        meta: {
            title: 'Dashboard',
            auth: true,
            group: 3
        }
    },
    {
        path: '/expert/our-portfolio',      
        meta: { label: 'Our Portfolio', },
        component: {
            render(c) { return c('router-view') }
        },
        children: [
            {
                path: '',
                name: 'expert-our-portfolio',
                component: () => import('@/views/itees/expert-dashboard/portfolio/Portfolio.vue'),
                meta: {
                    auth: true,
                    group: 3,
                    title: 'Our Portfolio',                    
                },            
            },     
            {
                path: '/expert/our-portfolio/project',
                name: 'expert-our-portfolio-project',
                redirect: '/expert/our-portfolio/project',
                meta: {                    
                    label: 'Project Listing',
                    auth: true,
                    group: 3,
                    navActiveLink: 'expert-our-portfolio'
                },            
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/views/itees/expert-dashboard/portfolio/Project.vue'),
                        meta: {
                            title: 'Project Listing',
                            auth: true,
                            group: 3
                        },                
                    },  
                    {
                        path: ':id',                  
                        name: 'expert-our-portfolio-project-detail',      
                        meta: {label: '--', navActiveLink: 'expert-our-portfolio'},
                        component: {
                            render(c) { return c('router-view') }
                        },
                        children: [
                            {
                                path: '',
                                name: 'expert-our-portfolio-project-detail-blank',
                                component: () => import('@/views/itees/expert-dashboard/portfolio/projectListing/Single.vue'),
                                meta: {
                                    auth: true,
                                    group: 3,
                                    navActiveLink: 'expert-our-portfolio',                                       
                                },
                            },
                            {
                                path: 'edit/:project_id',
                                name: 'expert-our-portfolio-project-detail-edit',                             
                                component: () => import('@/views/itees/expert-dashboard/portfolio/Single.vue'),
                                meta: {
                                    title: '--',
                                    auth: true,
                                    group: 3,
                                    navActiveLink: 'expert-our-portfolio'
                                },
                                component: {
                                    render(c) { return c('router-view') }
                                },
                                children: [
                                     {
                                        path: '',
                                        name: 'expert-our-portfolio-project-detail-edit-blank',
                                        component: () => import('@/views/itees/expert-dashboard/portfolio/Single.vue'),
                                        meta: {
                                            label: '--',
                                            title: '--',
                                            auth: true,
                                            group: 3,
                                            navActiveLink: 'expert-our-portfolio'
                                        },
                                    },
                                ]
                            },
                        ]
                    }
                ]
            },
            {
                path: '/expert/our-portfolio/activity-listing',                
                redirect: '/expert/our-portfolio/activity-listing',
                meta: {
                    label: 'Activity Listing',                    
                    auth: true,
                    group: 3
                },            
                component: {
                    render(c) { return c('router-view') }
                },
                children: [
                    {
                        path: '',
                        name: 'expert-our-portfolio-activity-listing',
                        component: () => import('@/views/itees/expert-dashboard/portfolio/activityList/List.vue'),
                        meta: {
                            auth: true,
                            group: 3,
                            title: 'Activity Listing',    
                            navActiveLink: 'expert-our-portfolio',
                        },                
                    },  
                    {
                        path: 'activity/:id',
                        name: 'expert-our-portfolio-activity-listing-detail',
                        component: () => import('@/views/itees/expert-dashboard/portfolio/activityList/Single.vue'),
                        meta: {
                            auth: true,
                            group: 3,
                            label: '--',
                            title: '--',    
                            titleOnlyView: '--',
                            subLabelDefault: '-+-',
                            action_sublabel: '#',
                            navActiveLink: 'expert-our-portfolio',
                        },                
                    },  
                ]
            }
        ]
    },
    {
        path: '/expert/my-profile',
        name: 'expert-profile',
        component: () => import('@/views/itees/expert-dashboard/profile/Profile.vue'),
        meta: {
            title: 'My Profile',
            label: 'My Profile',
            // auth: true,
            // group: 3,
        }
    },
]