export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCountry(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/country/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountryID(ctx, id) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/country/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getNameCountry( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/country/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
