import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import activities from '@/controller/activities'
import auth from '@/controller/auth'
import country from '@/controller/country'
import project from '@/controller/project'
import loading from "./modules/loading"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    activities, 
    auth,
    country,
    project,
    loading
  },
  // strict: process.env.DEV,
})
