import jwtDefaultConfig from './jwtDefaultConfig'
import {API_URL} from '../../../config/index'
import store from '@/store'
import router from '@/router'
export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns

    this.axiosIns.defaults.baseURL = API_URL;

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        //set loadding
        store.commit("loading/setLoading", true);
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response =>{
        store.commit("loading/setLoading", false);
        return response;
      } ,
      error => {
        store.commit("loading/setLoading", false);
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // if (status === 410) {
        if (response && response.status === 410) {
          this.isAlreadyFetchingAccessToken = true
          localStorage.removeItem('access_token')
          localStorage.removeItem('userData')
          router.push({ name: 'auth-login' })
          return
        } 
        
         // if (status === 401) {
        if (response && response.status === 401) {
          if(router?.history?.current?.name === "auth-login"){
            return;
          }
          this.isAlreadyFetchingAccessToken = true
          localStorage.removeItem('access_token')
          localStorage.removeItem('userData')
          router.push({ name: 'auth-login' })
          return;
          if (!this.isAlreadyFetchingAccessToken) {
            let userData = JSON.parse(localStorage.getItem('userData'))
            store.dispatch('auth/refreshToken2', {
              refresh_token: userData.refresh_token
            })
            .then(response => {
              this.isAlreadyFetchingAccessToken = false
              this.onAccessTokenFetched(response.data.data.access_token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `Bearer ${accessToken}`
              this.axiosIns.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
              resolve(this.axiosIns(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return this.axiosIns.post('/login', ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }
}
