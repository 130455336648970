export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Dashboard: My activity projects
    fetchActivityProject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/project/my-active-project-dashboard', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectListing(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/project/project-listing-dashboard', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Dashboard: Project listing & Project listing - Overview tab
    fetchProjectListingOverview(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/project/list-overview', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Dashboard: Study Visits Listing
    fetchStudyVisitList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/study-visit/list-overview', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Project list - Activity - Complete - Developing - Terminated tabs
    fetchProjectList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/project/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectListOverviewDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/project/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectCountrySingle(ctx, id) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/country/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportCsv(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`api/project/export`, {
          params: params,
          responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    getCurrencyList( ctx, queryParams){
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/list/list-currency', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProjectList( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/project/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    detailProjectList( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/project/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProjectList( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/project/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteProjectItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/project/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // experts section
    listExpertsAssign(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-assign/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addExperts( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/expert-assign/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    deleteExpertItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/expert-assign/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listExperts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTVETExpert( ctx, { id, is_delete} ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/expert/delete/${id}?is_delete=${is_delete}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailTVETExpert( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/expert/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTVETExpert( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/expert/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    approveTVETExpert( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/expert/change-type/${params.id}/${params.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeTypeTVETExpert( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/expert/change-type/${params.id}/${params.status}`, params.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStaffExpert( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/expert/edit-staff-expert/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    // Activity tab
    listProjectActivity(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/activity/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteActivityItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/activity/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailActivityItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/activity/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // gifts tab
    listProjectGifts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/gift/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProjectGifts(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('file_upload', params.file_upload)
        formData.append('description', params.description)
        formData.append('presented_to', params.presented_to)
        formData.append('received_from', params.received_from)
        formData.append('remarks', params.remarks)  
        formData.append('gift_table_id', params.gift_table_id)  
        formData.append('gift_table_type', params.gift_table_type)  

        global.axios
          .post('/api/gift/create', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProjectGifts( ctx, params){
      return new Promise((resolve, reject) => {
          let formData = new FormData()
   
          formData.append('description', params.data.description)
          formData.append('presented_to', params.data.presented_to)
          formData.append('received_from', params.data.received_from)
          formData.append('remarks', params.data.remarks)   

          if(params.data.file_upload){
            formData.append('file_upload', params.data.file_upload)
          }      
          global.axios
            .post(`/api/gift/edit/${params.id}`, formData)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    deleteProjectGifts( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/gift/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Users tab
    listUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/user-assign/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUserAssign( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/user-assign/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    deleteUserAssign( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/user-assign/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listUsersExpert(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-assign/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUserParticipant( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/activity/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // activity
    listActivityParticipant(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/activity/list-activity-participants-in-project', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listActivityExpert(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/activity/list-acvitity-expert-assign', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addActivity( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/activity/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    editActivity( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/activity/edit/${params.id}`, params.data_edit)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportActivity(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/activity/export-activity`, {
          params: params,
          responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    // timeline
    addAttendanceTime( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/timeline/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    listAttendance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/timeline/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createOTP(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/create-otp/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportAttendance(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/timeline/export/${params.id}`, {
            params: params,
            responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    deleteAttendance( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/timeline/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailAttendance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAttendance( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/timeline/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    startProActivity( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/start/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    pauseProActivity( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/pause/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTimeProActivity( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/add-time/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    timelineCheckinDashboard(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/timeline/dashboard-participant', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listPartiTimeline( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/timeline/list-participant-of-timeline/${queryParams.id}`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatusCheckIn_Attendance(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/timeline/change-status-participant-checkin', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Evaluation
    listEvaluation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/evaluation/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEva( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/evaluation/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    editEva( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/evaluation/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    detailEva(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/evaluation/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEvaluation( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/evaluation/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    convertStatusEvaProActivity( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/evaluation/change-status/${params.id}/${params.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportEvaluation(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/evaluation/export-all/${params.id}`, {
            params: params,
            responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    exportEvaCsv(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/evaluation/dowload-csv/${params.id}`, {
            params: params,
            responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    exportEvaPdf(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/evaluation/dowload-pdf/${params.id}`, {
            params: params,
            responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    listPartiEval( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/submit-form-evaluation/list-submit-form-of-evaluation/${queryParams.id}`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Our portfolio - Study visit
    fetchStudyVisit(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/study-visit/list-overview', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    itemStudyVisit(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/study-visit/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailStudyVisit(ctx, { id }) {
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/study-visit/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addVisit( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/study-visit/create`, params )
          .then(response => resolve(response),)
          .catch(error => reject(error))
      })
    },
    editVisit( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/study-visit/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportVisit(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`api/study-visit/export`, {
          params: params,
          responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    detailVisit( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/study-visit/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVisit( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/study-visit/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    convertTerminateVisit( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/study-visit/change-status/${params.id}/${params.status}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Strategic Plan
    fetchStrategicPlan( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/strategic-plan/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createStrategicPlan( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/strategic-plan/create`, params )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStrategicPlan( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/strategic-plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailStrategicPlan( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/strategic-plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStrategicPlan( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/strategic-plan/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    //G/S/P
    fetchStrategicPlan_GoalsStra( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/attachedinfo-strategic-plan/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createGoal( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/attachedinfo-strategic-plan/create`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createStra( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/attachedinfo-strategic-plan/create`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteStrategicItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/attachedinfo-strategic-plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    copyGloalStra( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/attachedinfo-strategic-plan/duplicate/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailGoalStra( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/attachedinfo-strategic-plan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editGoalStra( ctx, params ) {
      return new Promise((resolve, reject) => {
          global.axios
            .put(`/api/attachedinfo-strategic-plan/edit/${params.id}`, params.data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    // program item list
    fetchStrategicPlan_ProgrammeList( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/programme/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProgramme( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/programme/create`, params )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProgrammeItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/programme/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listStaff( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/staff/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailProgrammeItem( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/programme/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProgramme( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/programme/edit/${params.id}`, params.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStatusUpdate( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/programme/add-status/${params.id}`, params.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Product
    listProducts( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/product/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/product/create`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProduct( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/product/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailProduct( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/product/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProduct( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/product/edit/${params.id}`, params.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSupTopic( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/sub-topic/create`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSupTopic( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/sub-topic/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTopic( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/sub-topic/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    detailTopic( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/sub-topic/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTopic( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/sub-topic/edit/${params.id}`, params.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listProductsDocument( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/attachment/list-document`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProDocument(ctx, params) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('attachable_id', params.attachable_id)
        formData.append('attachable_type', params.attachable_type)
        formData.append('name', params.name)
        formData.append('file_upload', params.file)
        formData.append('remarks', params.remarks)  

        global.axios
          .post('/api/attachment/create-document', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProDocument( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/attachment/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProDocument( ctx, params){
      return new Promise((resolve, reject) => {
          let formData = new FormData()

          formData.append('name', params.data.name)                  
          formData.append('remarks', params.data.remarks)  
          if(params.data.file){
            formData.append('file_upload', params.data.file)
          }      

          global.axios
            .post(`/api/attachment/edit-document/${params.id}`, formData)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    // Participant manager
    listPart( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/participant/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportListPart(ctx, params) {
      return new Promise((resolve, reject) => {
        global.axios.get(`/api/participant/export-list`, {
          params: params,
          responseType: 'blob'
        })
        .then(response => {
            resolve(response);
        })
        .catch(error => reject(error));
      });
    },
    createPart( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .post(`/api/participant/create`, params )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPart( ctx, params ){
      return new Promise((resolve, reject) => {
        global.axios
          .put(`/api/participant/edit/${params.id}`, params.data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePart(ctx, { id, is_delete }) {
      return new Promise((resolve, reject) => {
        global.axios
          .delete(`/api/user/delete/${id}?is_delete=${is_delete}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    detailPart( ctx, { id } ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/participant/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listActivitiesPart( ctx, queryParams ){
      return new Promise((resolve, reject) => {
        global.axios
          .get(`/api/participant/list-acvitity-assign/${queryParams.id}`, { params: queryParams } )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // Expert role
    activityListExpert_dashboard(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-web/list-my-activity-dashboard', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    activityListExpert(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-web/list-activitiy', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ProjectListExpert(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/expert-web/list-project', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDashboardProjectAcitvity(ctx) {
      return new Promise((resolve, reject) => {
        global.axios
          .get('/api/project/my-active-project-dashboard')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
